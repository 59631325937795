import {
  Collection,
  createSearch as createApiSearch,
  getSearch as getApiSearch,
  getSearches as getApiSearches,
  getSearchesHistory as getApiSearchesHistory,
  PaginationOptions,
  removeSearch as removeApiSearch,
  Search as ApiSearch,
  SearchInput as ApiSearchInput,
  SearchListFilter,
} from '@/shared/api/search';
import { GoodType } from '@/shared/enums/goodType';
import { isLoadingDateType } from '@/shared/enums/loadingDateType';
import { isSound } from '@/shared/enums/sound';
import { isTruckType, TruckType } from '@/shared/enums/truckType';
import { deleteSearchColor, provideSearchColor, setSearchColor } from '@/shared/model/searchColorPlugin';
import {
  deleteSearchShortName,
  provideSearchShortName,
  setSearchShortName,
} from '@/shared/model/searchShortNamePlugin';
import { Search, SearchInput } from '@/shared/types';

export * from './search/index';

function composeApiSearchInput(input: SearchInput): ApiSearchInput {
  return {
    name: input.name,
    dateStart: input.dateStart,
    dateEnd: input.dateEnd,
    loadingDateType: input.loadingDateType ? Number(input.loadingDateType) : undefined,
    fromCountry: input.fromCountry,
    fromZones: input.fromZones,
    toCountry: input.toCountry,
    toZones: input.toZones,
    goodType: input.goodType ? Number(input.goodType) : undefined,
    truckType: input.truckType ? Number(input.truckType) : undefined,
    minWeight: input.minWeight,
    maxWeight: input.maxWeight,
    minLength: input.minLength,
    maxLength: input.maxLength,
    maxVolume: input.maxVolume,
    hazardousMaterials: input.hazardousMaterials,
    sendByEmail: input.sendByEmail,
    exchanges: input.exchanges,
    associatedSound: input.sound ? input.sound : undefined,
  };
}

function composeSearch(apiSearch: ApiSearch): Search {
  const loadingDateType = String(apiSearch.loadingDateType);
  const goodType = String(apiSearch.goodType) as GoodType;
  const truckType = String(apiSearch.truckType) as TruckType;
  const sound = String(apiSearch.associatedSound);

  return {
    id: apiSearch.id,
    name: apiSearch.name,
    dateStart: apiSearch.dateStart,
    dateEnd: apiSearch.dateEnd,
    loadingDateType: isLoadingDateType(loadingDateType) ? loadingDateType : null,
    fromCountry: apiSearch.fromCountry,
    fromZones: apiSearch.fromZones,
    toCountry: apiSearch.toCountry,
    toZones: apiSearch.toZones,
    goodType: goodType as GoodType,
    truckType: isTruckType(truckType) ? truckType : TruckType.ALL,
    minWeight: apiSearch.minWeight,
    maxWeight: apiSearch.maxWeight,
    minLength: apiSearch.minLength,
    maxLength: apiSearch.maxLength,
    maxVolume: apiSearch.maxVolume,
    hazardousMaterials: apiSearch.hazardousMaterials,
    exchanges: apiSearch.exchanges,
    sound: isSound(sound) ? sound : null,
    enabled: apiSearch.enabled,
    sendByEmail: apiSearch.sendByEmail,

    color: provideSearchColor(apiSearch.id),
    shortName: provideSearchShortName(apiSearch.id, apiSearch.name),
  };
}

export async function getSearch(id: string): Promise<Search | undefined> {
  const apiSearch = await getApiSearch(id);

  if (!apiSearch) {
    return undefined;
  }

  return composeSearch(apiSearch);
}

export async function getSearches(filter: SearchListFilter & PaginationOptions): Promise<Collection<Search>> {
  const collection = await getApiSearches(filter);

  return {
    ...collection,
    items: collection.items.map(composeSearch),
  };
}

export async function getSearchesHistory(filter: PaginationOptions): Promise<Collection<Search>> {
  const collection = await getApiSearchesHistory(filter);

  return {
    ...collection,
    items: collection.items.map(composeSearch),
  };
}

export async function createSearch(input: SearchInput): Promise<Search> {
  const search = await createApiSearch(composeApiSearchInput(input));

  setSearchColor(search.id, input.color);
  setSearchShortName(search.id, input.shortName);

  return composeSearch(search);
}

export async function removeSearch(id: string): Promise<void> {
  await removeApiSearch(id);

  deleteSearchColor(id);
  deleteSearchShortName(id);
}
