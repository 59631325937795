import axios from 'axios';

import { setupAuthentication } from '@/shared/api/util/interceptors/setupAuthentication';
import { setupUserLanguage } from '@/shared/api/util/interceptors/setupUserLanguage';
import config from '@/shared/config';
import { AxiosRestClient } from '@/shared/dataProviders/common/AxiosRestClient';
import { RestClient } from '@/shared/dataProviders/common/RestClient';

import { EphemeralSearchDataProvider } from './EphemeralSearchDataProvider';

export * from './types';

let ephemeralSearchDataProvider: EphemeralSearchDataProvider;
export function useEphemeralSearchDataProvider(): EphemeralSearchDataProvider {
  if (!ephemeralSearchDataProvider) {
    ephemeralSearchDataProvider = new EphemeralSearchDataProvider(useRestClient());
  }
  return ephemeralSearchDataProvider;
}

let client: RestClient;
function useRestClient(): RestClient {
  if (!client) {
    const axiosInstance = axios.create({
      baseURL: config.urls.searchApi,
    });

    setupAuthentication(axiosInstance);
    setupUserLanguage(axiosInstance);

    client = new AxiosRestClient(axiosInstance);
  }
  return client;
}
