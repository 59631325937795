import { DateTime } from 'luxon';

import { mapToCustomerContact } from '@/shared/dataProviders/common/b2pweb';
import { GoodType } from '@/shared/enums/goodType';
import { TruckType } from '@/shared/enums/truckType';
import { serializeZones } from '@/shared/utils';

import {
  B2PWebEphemeralSearch,
  B2PWebEphemeralSearchLocation,
  B2PWebEphemeralSearchLocationByCity,
  B2PWebEphemeralSearchLocationByZones,
  B2PWebEphemeralSearchOffer,
  B2PWebLocation,
} from './api';
import {
  EphemeralSearch,
  EphemeralSearchLocation,
  EphemeralSearchLocationByCity,
  EphemeralSearchLocationByZones,
  EphemeralSearchOffer,
  Location,
} from './types';

export function mapToB2PWebEphemeralSearch(source: EphemeralSearch): B2PWebEphemeralSearch {
  return {
    hazardousMaterials: source.hazardousMaterials,
    goodType: source.goodType,
    truckType: source.truckType,
    loadingDateType: source.loadingDateType,
    dateStart: source.dateStart?.toISODate(),
    dateEnd: source.dateEnd?.toISODate(),
    from: source.from ? mapToB2PWebEphemeralSearchLocation(source.from) : undefined,
    to: source.to ? mapToB2PWebEphemeralSearchLocation(source.to) : undefined,
    maxWeight: source.maxWeight,
    minWeight: source.minWeight,
    maxLength: source.maxLength,
    minLength: source.minLength,
    maxVolume: source.maxVolume,
    exchanges: source.exchanges,
  };
}

function mapToB2PWebEphemeralSearchLocation(source: EphemeralSearchLocation): B2PWebEphemeralSearchLocation {
  return source.type === 'city'
    ? mapToB2PWebEphemeralSearchLocationByCity(source)
    : mapToB2PWebEphemeralSearchLocationByZones(source);
}

function mapToB2PWebEphemeralSearchLocationByCity(
  source: EphemeralSearchLocationByCity
): B2PWebEphemeralSearchLocationByCity {
  return {
    type: 'city',
    latitude: source.latitude,
    longitude: source.longitude,
    radius: source.radius,
  };
}

function mapToB2PWebEphemeralSearchLocationByZones(
  source: EphemeralSearchLocationByZones
): B2PWebEphemeralSearchLocationByZones {
  return {
    type: 'zones',
    country: source.country,
    zones: source.zones ? [serializeZones(source.zones)] : undefined,
  };
}

export function mapToEphemeralSearchOffer(source: B2PWebEphemeralSearchOffer): EphemeralSearchOffer {
  return {
    id: source.id,
    customer: mapToCustomerContact(source.customer),
    forbiddingLevel: source.forbiddingLevel,
    hazardousMaterials: source.hazardousMaterials,
    goodType: source.goodType as GoodType,
    truckTypes: source.truckTypes as TruckType[],
    lading: mapToLocation(source.lading),
    delivery: mapToLocation(source.delivery),
    weight: source.weight,
    length: source.length,
    volume: source.volume,
    distance: source.distance,
    createdAt: DateTime.fromISO(source.createdAt),
    updatedAt: DateTime.fromISO(source.updatedAt),
  };
}

function mapToLocation(source: B2PWebLocation): Location {
  return {
    city: source.city,
    zipCode: source.zipCode,
    zone: source.zone,
    country: source.country,
    latitude: source.latitude,
    longitude: source.longitude,
    startDate: source.dateStart ? DateTime.fromISO(source.dateStart, { setZone: true }) : null,
    endDate: source.dateEnd ? DateTime.fromISO(source.dateEnd, { setZone: true }) : null,
  };
}
