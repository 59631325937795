export interface AbortOptions {
  readonly signal?: AbortSignal;
}

export interface PaginationOptions {
  readonly page?: number;
  readonly perPage?: number;
}

export interface SortingOptions<T extends string = string> {
  readonly sort?: T;
}

export interface Collection<ItemType> {
  items: ItemType[];
  count: number;
  totalCount: number;
  page: number;
  pageCount: number;
  perPage: number;
}

export interface EnumItem {
  key: string;
  value: string;
}

export class ValidationError extends Error {
  constructor(message: string, public readonly errors: ValidationFieldError[] = []) {
    super(message);
  }
}

export interface ValidationFieldError {
  field: string;
  code: string;
  message: string;
}

export interface Contact {
  name: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  email?: string;
  languages?: string[];
}

export interface CustomerContact {
  id: string;
  name: string;
  country: string;
  logo?: string;
}
