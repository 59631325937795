export interface AbortOptions {
  readonly signal?: AbortSignal;
}

export interface PaginationOptions {
  readonly page?: number;
  readonly perPage?: number;
}

export interface SortingOptions {
  readonly sort?: string;
}

export interface SearchOptions extends AbortOptions, PaginationOptions, SortingOptions {
  readonly ids?: string[];
  readonly name?: string;
  readonly siretNumber?: string;
  readonly vatNumber?: string;
  readonly country?: string;
  readonly zones?: string;
  readonly vehicleCertifications?: VehicleCertification[];
  readonly vehicleCharacteristics?: VehicleCharacteristic[];
  readonly vehicleTypes?: string[];
  readonly allowSubcontracting?: string;
  readonly allowLoading?: string;
}

export interface Collection<ItemType> {
  readonly items: ItemType[];
  readonly pagination: {
    readonly limit: number;
    readonly pageCount: number;
    readonly currentPage: number;
    readonly totalCount: number;
  };
}

export interface CustomerScoreListResponse {
  items: Record<string, CustomerScore>;
}

export interface CustomerScore {
  readonly customerId: string;
  readonly score: string | null;
}

export interface SearchResult {
  readonly id: string;
  readonly name: string;
  readonly country: string;
  readonly zipCode: string;
  readonly city: string;
  readonly siret: string;
  readonly logo: string | null;
  readonly directoryScore: number | null;
  readonly score: string | null;
  readonly forbiddings: {
    loading: LoadingRestrictionType;
    subcontracting: SubcontractingRestrictionType;
  };
}

export interface CustomerFile {
  readonly id: string;
  readonly customer: Customer;
  readonly certifications: VehicleCertification[];
  readonly axes: Axis[];
  readonly vehicleTypes: VehicleType[];
  readonly languages: string[];
  readonly activity: string | null;
  readonly directoryScore: number | null;
  readonly managers: {
    readonly companyManagerName: string | null;
    readonly salesManagerName: string | null;
    readonly charteringManagerName: string | null;
    readonly itManagerName: string | null;
    readonly operationsManagerName: string | null;
  };
  readonly url: string | null;
  readonly effective: number | null;
  readonly storageArea: string | null;
  readonly distribution: string | null;
  readonly multimodalTransportation: string | null;
  readonly isB2pSubcontractor: boolean;
  readonly isSubcontractor: boolean;
}

export interface MyCustomerFile {
  readonly customer: Customer;
  readonly certifications: VehicleCertification[];
  readonly axes: MyAxis[];
  readonly vehicleTypes: VehicleType[];
  readonly languages: string[];
  readonly activity: string | null;
  readonly directoryScore: number | null;
  readonly managers: {
    readonly companyManagerName: string | null;
    readonly salesManagerName: string | null;
    readonly charteringManagerName: string | null;
    readonly itManagerName: string | null;
    readonly operationsManagerName: string | null;
  };
  readonly documentOwner: {
    readonly name: string | null;
    readonly email: string | null;
    readonly phone: string | null;
  };
  readonly url: string | null;
  readonly effective: number | null;
  readonly storageArea: string | null;
  readonly distribution: string | null;
  readonly multimodalTransportation: string | null;
  readonly isB2pSubcontractor: boolean;
  readonly isSubcontractor: boolean;
}

export interface Customer {
  readonly id: string;
  readonly name: string;
  readonly address1: string;
  readonly address2: string | null;
  readonly address3: string | null;
  readonly zipCode: string;
  readonly city: string | null;
  readonly country: string;
  readonly siret: string;
  readonly tva: string | null;
  readonly napCode: string | null;
  readonly phone: string | null;
  readonly fax: string | null;
  readonly logo: string;
}

export interface MyAxis extends Axis {
  readonly id: string;
}

export interface Axis {
  readonly lading: AxisLocation;
  readonly delivery: AxisLocation;
}

export interface AxisLocation {
  readonly country: string | null;
  readonly zones: string[];
}

export interface VehicleType {
  readonly id: string;
  readonly typeId: number | null;
  readonly quantity: number | null;
  readonly characteristics: VehicleCharacteristic[];
  readonly comments: string | null;
}

export interface Comment {
  readonly id: string;
  readonly userId: string;
  readonly userName: string;
  readonly message: string;
  readonly createdAt: string;
}

export interface CommentInput {
  readonly message: string;
}

export interface Document {
  readonly documentType: {
    readonly id: number;
    readonly label: string;
    readonly mandatory: boolean;
  };
  readonly documentStatus: {
    readonly label: string;
    readonly value: number;
    readonly isValid: boolean;
  };
  readonly recall: {
    readonly typeId: number;
    readonly typeLabel: string;
    readonly date: string;
  };
  readonly expiredAt: string;
}

export interface AxisLocation {
  readonly country: string | null;
  readonly zones: string[];
}

export interface Axis {
  readonly id: string;
  readonly lading: AxisLocation;
  readonly delivery: AxisLocation;
}

export interface AxisInput {
  readonly lading: AxisLocation;
  readonly delivery: AxisLocation;
}

export interface MyCustomerFileInput {
  readonly certifications: VehicleCertification[];
  readonly activity: string | null;
  readonly managers: {
    readonly companyManagerName: string | null;
    readonly salesManagerName: string | null;
    readonly charteringManagerName: string | null;
    readonly itManagerName: string | null;
    readonly operationsManagerName: string | null;
  };
  readonly url: string | null;
  readonly effective: number | null;
  readonly storageArea: string | null;
  readonly distribution: string | null;
  readonly multimodalTransportation: string | null;
}

export interface VehicleType {
  readonly id: string;
  readonly typeId: number | null;
  readonly quantity: number | null;
  readonly characteristics: VehicleCharacteristic[];
  readonly comments: string | null;
}

export interface VehicleTypeInput {
  readonly typeId: number | null;
  readonly quantity: number | null;
  readonly characteristics: VehicleCharacteristic[];
  readonly comments: string | null;
}

export interface MyDocument extends Document {
  readonly canBeSent: boolean;
}

export enum LoadingRestrictionType {
  Allowed = 'allowed',
  Forbidden = 'forbidden',
  ForbiddenLocked = 'forbidden_locked',
  Warned = 'warned',
  WarnedLocked = 'warned_locked',
}

export enum SubcontractingRestrictionType {
  Allowed = 'allowed',
  Forbidden = 'forbidden',
  ForbiddenLocked = 'forbidden_locked',
}

export enum VehicleCertification {
  ADR = 1,
  ISO = 2,
  OEA = 3,
  Qualimat = 4,
}

export enum VehicleCharacteristic {
  Brackets = 'EQ',
  Crane = 'GR',
  FortyFourTons = '44T',
  MovingBottom = 'FM',
  Palettisable = 'PA',
  SemiTrailer = 'SR',
  Straps = 'SA',
  Tailgate = 'HA',
  TrailerTruck = 'CR',
  TrolleyLoader = 'CE',
}

export interface EnumItem<KeyType = string> {
  key: KeyType;
  value: string;
}
