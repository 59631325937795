export enum ParameterType {
  SHARED_OFFER = 'SHARED_OFFER',
  OFFER_DELETE_CAUSE = 'OFFER_DELETE_CAUSE',
}

export enum SharedOfferValue {
  DISABLED = '0',
  VIEW = '1',
  MANAGE = '2',
}

export enum OfferDeleteCauseValue {
  DISABLED = '0',
  ENABLED = '1',
}

export type ParameterMap = {
  [ParameterType.SHARED_OFFER]: SharedOfferValue;
  [ParameterType.OFFER_DELETE_CAUSE]: OfferDeleteCauseValue;
};

export interface Parameter<T extends ParameterType> {
  id: T;
  value: ParameterMap[T];
}

export interface UserInformation {
  civility: string;
  lastName: string;
  phone: string;
  mobile: string;
  fax: string;
  email: string;
  languages: string[];
}

export enum UserParameterType {
  GedtransApiKey = 'GEDTRANS_API_KEY',
  SharedOfferNotification = 'SHARED_OFFER_NOTIFICATION',
}

export interface UserParameter {
  id: string;
  value: string;
}

export interface UserSettings {
  maxSearchNumberAllowed: number;
}

export interface TimeSettings {
  time: number;
}
