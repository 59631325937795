import { DateTime } from 'luxon';

import axios, { isAxiosError } from './axios';
import { ApiCollectionResponse, Collection, FormValidationError, PaginationOptions, ValidationError } from './utils';

const apiMapping = {
  date_start: 'dateStart',
  date_end: 'dateEnd',
  loading_date_type: 'dateType',
  from_zones: 'fromZones',
  from_country: 'fromCountry',
  to_zones: 'toZones',
  to_country: 'toCountry',
  good_type: 'goodType',
  truck_type: 'truckType',
  min_weight: 'minWeight',
  max_weight: 'maxWeight',
  min_length: 'minLength',
  max_length: 'maxLength',
  max_volume: 'maxVolume',
  hazardous_materials: 'hazardousMaterials',
  associated_sound: 'associatedSound',
  created_at: 'createdAt',
};

function isKeyOfApiMapping(key: string): key is keyof typeof apiMapping {
  return key in apiMapping;
}

interface ApiSearch {
  id: string;
  name: string | null;
  dateStart: string;
  dateEnd: string;
  loadingDateType: number;
  fromZones: string[];
  fromCountry: string;
  toZones: string[];
  toCountry: string;
  goodType: number;
  truckType: number;
  minWeight: number | null;
  maxWeight: number | null;
  minLength: number | null;
  maxLength: number | null;
  maxVolume: number | null;
  hazardousMaterials: boolean | null;
  exchanges: string[];
  associatedSound: string;
  enabled: boolean | null;
  sendByEmail: boolean;
  createdAt: string;
}

export interface Search {
  id: string;
  name: string;
  dateStart: DateTime | null;
  dateEnd: DateTime | null;
  loadingDateType: number;
  fromZones: string[];
  fromCountry: string;
  toZones: string[];
  toCountry: string;
  goodType: number;
  truckType: number;
  minWeight: number | null;
  maxWeight: number | null;
  minLength: number | null;
  maxLength: number | null;
  maxVolume: number | null;
  hazardousMaterials: boolean | null;
  exchanges: string[];
  associatedSound: string;
  enabled: boolean;
  sendByEmail: boolean;
}

// Legacy encode on submit
// @todo Remove after legacy migration (refs #B2P-385)
function htmlspecialchars_decode(text: string) {
  return text
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
}

function fromApi(apiSearch: ApiSearch): Search {
  return {
    id: apiSearch.id,
    name: htmlspecialchars_decode(apiSearch.name || ''),
    dateStart: apiSearch.dateStart ? DateTime.fromISO(apiSearch.dateStart, { setZone: true }) : null,
    dateEnd: apiSearch.dateEnd ? DateTime.fromISO(apiSearch.dateEnd, { setZone: true }) : null,
    loadingDateType: apiSearch.loadingDateType,
    fromCountry: apiSearch.fromCountry,
    fromZones: apiSearch.fromZones,
    toCountry: apiSearch.toCountry,
    toZones: apiSearch.toZones,
    goodType: apiSearch.goodType,
    truckType: apiSearch.truckType,
    minWeight: apiSearch.minWeight,
    maxWeight: apiSearch.maxWeight,
    minLength: apiSearch.minLength,
    maxLength: apiSearch.maxLength,
    maxVolume: apiSearch.maxVolume,
    hazardousMaterials: apiSearch.hazardousMaterials,
    exchanges: apiSearch.exchanges,
    associatedSound: apiSearch.associatedSound,
    enabled: !!apiSearch.enabled,
    sendByEmail: apiSearch.sendByEmail,
  };
}

export interface SearchInput {
  name?: string;
  dateStart?: DateTime;
  dateEnd?: DateTime;
  loadingDateType?: number;
  fromCountry?: string;
  fromZones?: string[];
  toCountry?: string;
  toZones?: string[];
  goodType?: number;
  truckType?: number;
  minWeight?: number;
  maxWeight?: number;
  minLength?: number;
  maxLength?: number;
  maxVolume?: number;
  hazardousMaterials?: boolean | null;
  exchanges: string[];
  associatedSound?: string;
  sendByEmail?: boolean;
}

export interface SearchListFilter {
  enabled?: boolean;
}

export async function list(
  { enabled, perPage, page }: SearchListFilter & PaginationOptions = { enabled: true, page: 1 }
): Promise<Collection<Search>> {
  const { data } = await axios.get<ApiCollectionResponse<ApiSearch>>('searches', {
    params: {
      enabled,
      per_page: perPage,
      page,
    },
  });

  return {
    items: data.items.map(fromApi),
    page: data.pagination.currentPage,
    pageCount: data.pagination.pageCount,
    limit: data.pagination.limit,
    totalCount: data.pagination.totalCount,
  };
}

export async function history({ perPage, page }: PaginationOptions = { page: 1 }): Promise<Collection<Search>> {
  const { data } = await axios.get<ApiCollectionResponse<ApiSearch>>('searches/history', {
    params: {
      per_page: perPage,
      page,
    },
  });

  return {
    items: data.items.map(fromApi),
    page: data.pagination.currentPage,
    pageCount: data.pagination.pageCount,
    limit: data.pagination.limit,
    totalCount: data.pagination.totalCount,
  };
}

export async function get(id: string): Promise<Search | undefined> {
  try {
    const { data } = await axios.get<ApiSearch>(`searches/${id}`);

    return fromApi(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return undefined;
    }

    throw e;
  }
}

export async function create(data: SearchInput): Promise<Search> {
  const form = {
    ...data,

    dateStart: data.dateStart?.toFormat('yyyy-MM-dd'),
    dateEnd: data.dateEnd?.toFormat('yyyy-MM-dd'),
  };

  try {
    const { data } = await axios.post<ApiSearch>(`searches`, form);
    return fromApi(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError(
        'Error occurred while creating search',
        e.response.data.errors?.map((error: ValidationError) => {
          if (isKeyOfApiMapping(error.field)) {
            error.field = apiMapping[error.field];
          }
          return error;
        }) ?? []
      );
    }

    throw e;
  }
}

export async function update(id: string, data: SearchInput): Promise<Search> {
  const form = {
    ...data,

    dateStart: data.dateStart?.toFormat('yyyy-MM-dd'),
    dateEnd: data.dateEnd?.toFormat('yyyy-MM-dd'),
  };

  try {
    const { data } = await axios.put<ApiSearch>(`searches/${id}`, form);
    return fromApi(data);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      throw new FormValidationError(
        'Error occurred while updating search',
        e.response.data.errors?.map((error: ValidationError) => {
          if (isKeyOfApiMapping(error.field)) {
            error.field = apiMapping[error.field];
          }
          return error;
        }) ?? []
      );
    }

    throw e;
  }
}

export async function remove(id: string): Promise<void> {
  try {
    await axios.delete<void>(`searches/${id}`);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return;
    }

    throw e;
  }
}
